.events {
  width: 1000px;
  display: flex;
  flex-direction: column;
  margin: 30px auto;

  @media screen and (max-width: 800px) {
    width: 100%;
  }

  &__list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media screen and (max-width: 800px) {
      flex-direction: column;
      flex-wrap: nowrap;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media screen and (max-width: 800px) {
      flex-direction: column-reverse;
    }
  }

  &__button {
    &_right-aligned {
      margin-left: auto;
    }
  }

  &__pagination {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__page {
    color: inherit;
    padding: 15px;
    background: #f9f9f9;
    margin-right: 15px;

    &:hover,
    &:focus {
      color: inherit;
      text-decoration: none;
      background: #d1d1d1;
    }

    &_acitve {
      color: inherit;
      background: #b1b1b1;
    }
  }

}

.popup {

  &__overlay {
    display: flex;
    justify-content: center;
    align-items: center;

    position: fixed;
    width: 100vw;
    height: 100vh;

    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 30;

    background-color: rgba(1, 1, 1, .5);

    overflow: auto;
  }

  &__content {
    background-color: #fff;
    z-index: 40;
    overflow: auto;
    min-width: 370px;
    margin: auto 0;

    @media screen and (max-width: 800px) {
      width: 100vw;
      height: 100vh;
      //transform: none;
      overflow-y: scroll;
    }
  }

  button {
    padding: 5px 12px;
  }

  input[type=text],
  input[type=password],
  input[type=email],
  input[type=number],
  textarea,
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;

    border: 1px solid #ccc;
    box-shadow: none;
    padding: 8px 12px;

    margin: 3px 0;
    flex: 1 0 auto;

    background: #fff;
    line-height: initial;

    &:disabled {
      background-color: #f9f9f9;
    }
  }

  .event__image {
    &:hover {
      cursor: default;
    }
  }

}

.event {

  display: flex;
  flex-direction: row;
  margin: 10px auto;
  background: #f9f9f9;
  padding: 30px;
  width: 100%;
  max-width: 800px;
  flex: 1 0 auto;

  @media screen and (max-width: 800px) {
    flex-direction: column-reverse;

    form {
      align-self: center;
    }
  }

  &__image {
    width: 285px;
    height: 210px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-right: 20px;
    cursor: pointer;
    flex: 1 0 auto;
    position: relative;

    .fa {
      background: #fff;
      color: #000;
      font-size: 1.1em;
      position: absolute;
      bottom: 5px;
      right: 5px;
      padding: 4px;
      border-radius: 3px;
      
    }

    @media screen and (max-width: 800px) {
      margin: 0 auto;
    }
  }

  &__title {
    font-size: 22px;
    //font-weight: bold;
  }

  &__summary {
    display: flex;
    flex-direction: column;

    p {
      margin: 0;
      padding: 0;
    }
  }

  &__data {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 360px;

    @media screen and (max-width: 800px) {
      min-width: 0;
    }
  }

  button {
    padding: 5px 12px;

    @media screen and (max-width: 800px) {
      margin: 5px 0;
    }
  }

  &__editing {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    margin-left: auto;
    align-self: flex-start;
  }

  &__actions {
    display: flex;
    margin: 10px 0;
    align-items: flex-end;
    flex-direction: row;
    flex-wrap: wrap;

    @media screen and (max-width: 800px) {

    }
  }

  &__primary-actions {
    display: flex;
    flex-direction: row;
    margin-left: auto;

    @media screen and (max-width: 800px) {
      flex-direction: column;
      width: 100%;

      button {
        padding: 2px 14px;
        width: 100%;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    > *:not(:last-child) {
      margin-bottom: 10px;
    }

    @media screen and (max-width: 800px) {
      margin-bottom: 30px;
    }
  }

  button {
    line-height: 25px;
  }

  &__price {
    line-height: 25px;
    margin-top: auto;
    font-weight: 700;
    //background-color: #ff763b;
    //padding: 2px 14px;
    min-width: 70px;
  }

  &__status {
    color: #fff;
    padding: 5px 9px;
  }

  &__not-approved {
    background: #ffa73b;
  }

  &__approved {
    background: #32cc56;
  }

  &__declined {
    // padding: 5px 20px;
    background-color: #ff453b;
  }

  &__additional-actions {
    width: 100%;
  }

  &__free-link {
    padding: 7px 10px;
    min-width: 300px;

    @media screen and (max-width: 800px) {
      min-width: 100%;
    }
  }

  &__share {
    display: flex;
    flex-direction: row;
    text-align: right;
    align-items: center;
    justify-content: flex-end;

    @media screen and (max-width: 800px) {
      flex-direction: column;
    }
  }

}

.event-modal {
  display: flex;
  flex-direction: column;
  min-width: 450px;
  max-width: 850px;

  @media screen and (max-width: 800px) {
    min-width: 100%;
    width: 100vw;
  }

  &__body {
    padding: 25px;
  }

  &__header {
    display: flex;
    flex-direction: row;
    padding: 15px 25px 0;
  }

  &__close {
    color: inherit;
    margin-left: auto;
    font-size: 16px;

    &:hover,
    &:focus {
      color: inherit;
      text-decoration: none;
    }
  }
  
  &__actions {
    display: flex;
    flex-direction: row;
    margin: 30px 0;

    &_centered {
      justify-content: center;
    }

    button {
      margin: 0 3px;
    }
  }

  &__title {
    font-size: 18px;
    padding: 0;
    margin: 0;
  }

  &__form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__summary {

  }

  &__field {
    display: flex;
    flex-direction: column;
    margin: 7px 0;
    flex: 1 1 auto;

    &_group {
      flex-direction: row;
      align-items: flex-start;
      margin: 0;

      > * {
        width: 100%;
      }

      > *:not(:first-child) {
        margin-left: 10px;

        @media screen and (max-width: 800px) {
          margin-left: 0;
          flex-direction: column;
        }
      }

      @media screen and (max-width: 800px) {
        margin-left: 0;
        flex-direction: column;
      }
    }

    .duration-select {

    }

    .ui-datepicker {
      width: 100%;
    }

    label {
      position: relative;

      input[type=text],
      input[type=password],
      input[type=email],
      input[type=number],
      textarea,
      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        width: 100%;
      }

      i.fa {
        display: block;
        width: 32px;
        //height: 100%;
        background-color: #fff;
        position: absolute;
        right: 3px;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);
        font-size: 16px;
        line-height: 30px;
      }
    }

    &__description {
      font-size: 15px;
      margin: 0 0 3px 0;
    }

    textarea {
      resize: vertical;
    }

    @media screen and (max-width: 800px) {
      margin-left: 0;
    }

  }

  &__summary {
    text-align: center;
  }

  &__ticket-section {
    display: flex;
    flex-direction: row;
  }

}

.shows {

  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 30px;

    @media screen and (max-width: 800px) {
      justify-content: center;
      margin: 0 -30px;
    }
  }
  .show {
    width: 379px;
    height: 280px;
    margin: 8px;
    position: relative;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
    color: #fff;

    &_active {
      &:hover {
        display: none;
        cursor: pointer;
      }
    }

    &__buy-ticket {
      position: absolute;
      bottom: 4px;
      right: 4px;

      &:hover {
        cursor: pointer;
      }

      &.purchased {
        color: #000;
        background-color: #3bff69;

        &:hover,
        &:focus,
        &:hover[disabled] {
          color: #000;
          background-color: #3bff69;
          cursor: default;
        }
      }
    }

    &__preview {
      width: 285px;
      height: 210px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      margin-right: 20px;
      cursor: pointer;
    }

    .ss_info {
      position: absolute;
      bottom: 5px;
      left: 5px;
      display: grid;
      grid-template:
              'th date'
              'th sn';
      .ss_th {
        grid-area: th;
        align-self: center;
        margin-right: 5px;
        width: auto;
        height: auto;
        border-radius: 50%;
        overflow: hidden;
      }
      .ss_date {
        grid-area: date;
      }
      .ss_owner {
        grid-area: sn;
      }
    }

    &__header {
      display: inline-block;
      min-width: 150px;
      background-color: #000;
      color: #fff;
      font-size: 18px;
      padding: 8px 35px;
      margin: 0;
      width: 100%;
      word-break: break-all;
      text-overflow: ellipsis;

      >  .show__language {
        position: absolute;
        left: 5px;
        top: 5px;
      }
    }

    @media only screen and (min-width : 1540px) {
      margin-left: 4px;
      margin-right: 4px;
    }

    .ss_th {
      width: 100%;
      height: 100%;
      float: left;
      position: relative;
      overflow: hidden;

      .ss_event-image_preview {
        position: absolute;
        border: 0 none;
      }
    }

    .m_th {
      padding: 3px;
      img {
        float: left;
        width: 87px;
        height: 87px;
      }
    }

  }

}


.calendar {
  width: 1000px;
  display: flex;
  flex-direction: column;
  margin: 30px auto;

  tbody {
    margin: 0 auto;
  }

  tr {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  td {
    min-width: 30px;
    height: 30px;
    padding: 5px;
    border: 1px solid #000;
    text-align: center;
    font-family: "Helvetica", serif;

    &.hour {
      padding: 5px 0;

      sup {
        font-size: .6em;
        top: -1em;
      }
    }

    &.day small {
      opacity: .8;
    }

    &.checked {
      color: #000;
      background-color: #aaa;
    }
  }

}

.pull-bottom {
  margin-top: auto;
}

.ss_event-image {
  width: 180px;
}
.ss_event-image_preview {
  width: 180px;
  height: 150px;
  border: 1px solid rgba(34,36,38,.15);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hidden {
  display: none !important;
}
